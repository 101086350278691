import React from 'react'
import { Segment, Container, Image, Header, Grid } from 'semantic-ui-react'
import Layout from '../../components/layout'
import PageHeader from '../../components/PageHeader'
import { FormattedMessage } from 'gatsby-plugin-intl'

import logo from '../../images/surface_dark_background.svg'
import picto_selected_variables from '../../images/magnify_data.svg'
import picto_groups from '../../images/dashboard.svg'
import picto_lift_curves from '../../images/lift_curves.svg'

const SolutionSurfacePrimary = () => (
  <Segment vertical>
    <Segment basic>
      <Container text textAlign="justified">
        <Header as="h3" size="large" textAlign="center">
          <Header.Content>
            <FormattedMessage id="subtitle-solution-surface" />
          </Header.Content>
        </Header>
        <p>
          <FormattedMessage id="content-solution-surface-1" />
        </p>
      </Container>
    </Segment>

    <Segment as="section" vertical className="primary">
      <Grid container stackable centered verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={4}>
            <Image
              src={picto_selected_variables}
              alt="picto_selected_variables"
              centered
              size="small"
            />
          </Grid.Column>

          <Grid.Column width={10} textAlign="justified">
            <Header as="h2">
              <FormattedMessage id="section-title-solution-surface-1" />
            </Header>
            <Container text fluid>
              <p>
                <FormattedMessage id="section-content-solution-surface-1" />
              </p>
            </Container>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <Image
              src={picto_groups}
              alt="picto_groups"
              centered
              size="small"
            />
          </Grid.Column>

          <Grid.Column width={10} textAlign="justified">
            <Header as="h2">
              <FormattedMessage id="section-title-solution-surface-2" />
            </Header>
            <Container text fluid>
              <p>
                <FormattedMessage id="section-content-solution-surface-2" />
              </p>
            </Container>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <Image
              src={picto_lift_curves}
              alt="picto_lift_curves"
              centered
              size="small"
            />
          </Grid.Column>

          <Grid.Column width={10} textAlign="justified">
            <Header as="h2">
              <FormattedMessage id="section-title-solution-surface-3" />
            </Header>
            <Container text fluid>
              <p>
                <FormattedMessage id="section-content-solution-surface-3" />
              </p>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </Segment>
)

const SolutionSurface = () => (
  <Layout>
    <div className="SolutionSurfacePage">
      <PageHeader fullWidth>
        <Image src={logo} alt="Surface" centered size="medium" />
      </PageHeader>
      <SolutionSurfacePrimary />
    </div>
  </Layout>
)

export default SolutionSurface
